import React, { useState, useEffect } from "react";

const App = () => {
const [excuse, setExcuse] = useState("");
const [synth, setSynth] = useState(null);
const [excuses, setExcuses] = useState([]);

useEffect(() => {
const synth = window.speechSynthesis;
setSynth(synth);
    const fetchExcuses = async () => {
      try {
        const response = await fetch("excuses.txt");
        const text = await response.text();
        const excusesArray = text.split("\n").filter(excuse => excuse.trim() !== "");
        setExcuses(excusesArray);
      } catch (error) {
        console.error(error);
      }
    };
    fetchExcuses();}, []);

    const generateExcuse = () => {
    const gender = Math.random() < 0.5 ? "male" : "female";
    const voices = synth.getVoices().filter(voice => voice.name.includes(gender));
    const voiceIndex = Math.floor(Math.random() * voices.length);
    const voice = voices[voiceIndex];
    const excuse = excuses[Math.floor(Math.random() * excuses.length)];
    const utterance = new SpeechSynthesisUtterance(excuse);
    utterance.voice = voice;
    utterance.pitch = Math.max(Math.min(Math.random() * 2, 1.5), 0.5);
    utterance.rate = Math.max(Math.min(Math.random() * 2, 1.2), 0.8);
    synth.speak(utterance);
    setExcuse(excuse);
    };
    
    return (
    <div
    style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundImage: "url('https://c1.wallpaperflare.com/preview/60/194/382/golf-sunset-sport-golfer.jpg')",
    backgroundSize: "cover",
    backgroundPosition: "center"
    }}
    >
    <div
    style={{
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)"
    }}
    >
    <button
    style={{
    padding: "20px",
    backgroundColor: "red",
    color: "white",
    fontSize: "1.5rem",
    borderRadius: "10px",
    cursor: "pointer"
    }}
    onClick={generateExcuse}
    >
    Generate Excuse
    </button>
    <p style={{ marginTop: "20px", textAlign: "center" }}>{excuse}</p>
    </div>
    </div>
    );
    };
    
    export default App;